
//Fix for Training/Youth Page
$(function(){

    //Only on desktop
    if( $(window).width()>=767 ){
        setInterval(function(){
           $(".sc-training-row").each(function(){
                var the_sc_training_row_height = $(this).find(".trainingDESC").height();
                $(this).find(".trainingIMG").height( the_sc_training_row_height );
           });
        },1000);

        $(window).resize(function(){
           $(".sc-training-row").each(function(){
                var the_sc_training_row_height = $(this).find(".trainingDESC").height();
                $(this).find(".trainingIMG").height( the_sc_training_row_height );
           });
        });
    }
    
});

function icon_add() {

    var current_url = "" + window.location.href;
    var split_url = new Array();

    split_url = current_url.split("/");

    var current_domain = split_url[2];
    if (current_domain.indexOf('philmontscoutranch.org') == -1) {

        var i;

        var j;
        var articles = $("article");
        if (articles.length > 0) {
            var middle_element = $(articles[0]);

            var a_array = middle_element.find("a");

            var temp_href = "";

            var temp_extension = "";

            var split_href = new Array();

            var split_file = new Array();

            var split_extension = new Array();

            var link_image = "";

            for (i = 0; i < a_array.length; i++) {

                temp_href = "" + a_array[i].href;

                if (temp_href != "") {

                    temp_href = temp_href.toLowerCase();

                    link_image = "html";

                    split_href = temp_href.split("/");

                    if (split_href[split_href.length - 1].indexOf(".") != -1 && a_array[i].innerHTML.toLowerCase().indexOf("<img") == -1 && a_array[i].href.toLowerCase().indexOf("javascript") == -1) {

                        split_file = split_href[split_href.length - 1].split(".");

                        temp_extension = split_file[split_file.length - 1];

                        if (temp_extension.indexOf("?") != -1) {
                            split_extension = temp_extension.split("?");
                            temp_extension = split_extension[0];
                        }
                        if (temp_extension.indexOf("#") != -1) {
                            split_extension = temp_extension.split("#");
                            temp_extension = split_extension[0];
                        }

                        switch (temp_extension) {

                            case 'doc':
                            case 'docx':
                            case 'rtf':
                            case 'txt':
                                link_image = "word";
                                break;
                            case 'pdf':
                                link_image = "PDF";
                                break;
                            case 'swf':
                            case 'fla':
                            case 'swd':
                            case 'flv':
                                link_image = "flash";
                                break;
                            case 'xls':
                            case 'xlsx':
                            case 'csv':
                            case 'xlt':
                            case 'xlw':
                                link_image = "excel";
                                break;
                            case 'ppt':
                            case 'pptx':
                            case 'pps':
                                link_image = "powerpoint";
                                break;
                            case 'zip':
                            case 'gzip':
                            case 'rar':
                                link_image = "zip";
                                break;
                            case 'mov':
                                link_image = "mov";
                                break;
                            case 'mp3':
                            case 'mp4':
                                link_image = "music";
                                break;
                            default:
                                link_image = "html";
                                break;

                        }

                    }

                    if (temp_href.indexOf("mailto") != -1 && a_array[i].innerHTML.toLowerCase().indexOf("<img") == -1 && a_array[i].href.toLowerCase().indexOf("javascript") == -1) {

                        link_image = "MailTo";

                    }

                    if (temp_href.indexOf(current_domain) == -1 && a_array[i].innerHTML.toLowerCase().indexOf("<img") == -1 && a_array[i].href.toLowerCase().indexOf("javascript") == -1) {

                        a_array[i].innerHTML = a_array[i].innerHTML + " <img src=\"/wp-content/uploads/2018/01/link-" + link_image + ".gif\" alt=\"" + link_image + " icon\" border=\"0\" class=\"p0 valign-tbottom\">";

                    } else if (link_image != "html" && a_array[i].innerHTML.toLowerCase().indexOf("<img") == -1 && a_array[i].href.toLowerCase().indexOf("javascript") == -1) {

                        a_array[i].innerHTML = a_array[i].innerHTML + " <img src=\"/wp-content/uploads/2018/01/link-" + link_image + ".gif\" alt=\"" + link_image + " icon\" border=\"0\" class=\"p0 valign-tbottom\" >";

                    }

                }

            }
        }
    }

}

function add_submitToBTN() {
    let submitBtn = document.getElementById("search-council-btn");
    if( null !== submitBtn ) {
        submitBtn.addEventListener("click", function(){ 
            document.getElementById("modalScoutPrograms").submit();
        });
    }
}

function setPseudoCookie(key, todayDate) {
    let expirationDate = new Date();
    expirationDate.setMonth( expirationDate.getMonth() + 4 );
    const item = {
        created: todayDate.getTime(),
        expiry: expirationDate.getTime(),
    };
    localStorage.setItem(key, JSON.stringify(item));
}
  
function getPseudoCookie(key, todayDate) {
    const itemStr = localStorage.getItem(key);
  
    if (!itemStr) {
      return null;
    }
  
    const item = JSON.parse(itemStr);
  
    if ( todayDate.getTime() > item.expiry ) {
      
			localStorage.removeItem(key);
			setPseudoCookie("showModalBSA", todayDate );

			return null;
    }
		else{

		}
    return item;
}

document.addEventListener("DOMContentLoaded", function() {
    (function($) {
            $(function() {
      var roundtableParent = $(".parent-pageid-34999");
            // Check if the page is child page of Roundtable
            if(roundtableParent) {
                    // add DISABLED attribute and class to disabled submit button
                    $("#round-table-submit-btn").prop('disabled', true);
                    $("#round-table-submit-btn").addClass('disabled');

                    // Submit Button validation
                    var toRemove = 3,
                    initial = 0;
                    var toValidate = $('#form-field-name, #form-field-email, #form-field-message'),
                    valid = false;

                    function removeDisabled() {
                    if (toRemove === initial) {
                            $("#round-table-submit-btn").prop('disabled', false);
                            $("#round-table-submit-btn").removeClass('disabled');
                    } else {
                            $("#round-table-submit-btn").prop('disabled', true);
                            $("#round-table-submit-btn").addClass('disabled');
                    }
                    }

                    toValidate.change(function() {
                    if ($(this).val().length > 0) {
                            if (initial < toRemove) {
                            initial++;
                            }
                            removeDisabled();
                    } else {
                            initial--;
                            removeDisabled();
                    }
                    });
            }

            });
    })(jQuery);

});

jQuery( document ).on( 'elementor/popup/show', ( event, id, instance ) => {
    const modalvars =  JSON.parse(instance.$element[0].dataset.elementorSettings);
    if ( ( typeof(modalvars.classes) !== 'undefined' && modalvars.classes === 'welcomescouting-a' ) || ( typeof(modalvars.classes) !== 'undefined' && modalvars.classes === 'welcomescouting-b' ) ){
        window.onload = function(e){ 
            add_submitToBTN();
        }
        const now = new Date();
        if(localStorage.getItem("showModalBSA") === null){
          setPseudoCookie("showModalBSA", now);
          const pseudoCookie = getPseudoCookie("showModalBSA", now);
          let expiryDate = new Date(pseudoCookie.expiry);
          console.log("Creating - Expiry date: " + expiryDate.toDateString());
        }
        else{
             let pseudoCookie = getPseudoCookie("showModalBSA", now);
            if (null !== pseudoCookie ){
                const modal = jQuery(".dialog-close-button");
                modal.click();
            }
            let expiryDate = new Date(pseudoCookie.expiry);
            console.log("Existing - Expiry date: " + expiryDate.toDateString());
        }
    }
});
jQuery(function ($) {
    // * Zipcode error checker
    function isZipCode(value) {
      return /(^\d{5}$)/.test(value);
    }
  
    async function findUserZipCode(location) {
      const url = `https://nominatim.openstreetmap.org/reverse?lat=${location.coords.latitude}&lon=${location.coords.longitude}&format=json`;
      fetch(url).then((response) =>
        response
          .json()
          .then((data) => ({
            data: data,
            status: response.status,
          }))
          .then((res) => {
            document.getElementById("zip").value =
              res.data.address.postcode;
          })
      );
    }
  
    $("#location-button").click(function () {
      navigator.geolocation.getCurrentPosition(findUserZipCode);
    });
});
// $(function() {
//   icon_add(); /* Runs icon_add */
// });
